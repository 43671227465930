/* custom variables */

$color-f13: #ffcc00;
$color-bg-content: #ffffff;
$color-bg-body: #656565;
$color-bg-intro: #f0f0f0;

$primary: #337ab7;
$secondary: $color-f13;


$color-bg-footer: #444444;
$color-fg-footer: #ffffff;

$brand-danger: #cc0000;
$state-danger-text: #cc0000;
$page-border-color: #D5D3D7;
$gray-lighter: $page-border-color;

$font-size-base: 1rem;
$text-color: #3F4142;
$body-bg: $color-bg-content;
$tooltip-bg: $body-bg;
$btn-primary-color: $text-color;
$btn-primary-bg: $color-f13;

$grid-gutter-width: 20px;

$border-radius: 0;

$input-border: #ccc;
$input-border-focus: $color-f13;

$h1-font-size:                $font-size-base * 1.5;
$h2-font-size:                $font-size-base * 1.25;
$h3-font-size:                $font-size-base * 1.2;
$h4-font-size:                $font-size-base * 1.15;
$h5-font-size:                $font-size-base * 1.1;
$h6-font-size:                $font-size-base;

$navbar-default-bg: #656565;
$navbar-default-border: #888;
$navbar-default-color: #f8f8f8;
$navbar-default-link-color: $navbar-default-color;
$navbar-default-link-active-bg: lighten($navbar-default-bg, 6.5%);
$navbar-default-link-active-color: #fff;
$navbar-default-brand-color: #fff;
$navbar-default-brand-hover-color: #fff;

/* don't underline links by default */
$link-decoration: none;
$link-hover-decoration: underline;

/* pagination */
$pagination-color: $text-color;
$pagination-active-bg: $color-f13;
$pagination-active-color: $text-color;
$pagination-active-border-color: $color-f13;
