@keyframes enable-pointer-events {
  0% {
    pointer-events: none;
  }
  100% {
    pointer-events: all;
  }
}

header {
  .no-focus:focus {
    outline: 0;
    box-shadow: none;
  }

  .navbar-toggler {
    xborder: none;
  }

  .navbar {
    padding: 3px;
  }

  .fa {
    color: #ccc;
  }

  #header-brand-bar {
    background: $color-f13;
    .navbar-brand {
      font-weight: bold;

      img {
	transition: all .1s ease;
	position: fixed;
	top: 0px;
	margin-left: -120px;
	height: 120px;
	width: 120px;
	z-index: 50;
      }
    }
    .navbar-nav {
      .nav-link, .fa {
	color: #222;
      }
    }
  }

  #header-menu-bar {
    background: $color-bg-body;

    .offcanvas {
      background: $color-bg-body;
      --bs-offcanvas-width: 100%;
      &.showing,
      &.show {
	.dropdown-menu {
	  --bs-dropdown-bg: $color-bg-body;
	  border-left: 2px solid $color-f13;
	  margin-left: 0;
	}
      }
    }

    #navbar .navbar-nav > .nav-item > a {
      text-transform: uppercase;
      margin-right: 1em;
      font-weight: 450;

      &:first-child {
	padding-left: 0;
      }
    }
    a {
      color: #fff;
    }
    .dropdown-menu {
      @extend .pb-3;
      border: 0;
      border-top: 3px transparent solid;
      margin-left: -5px;
      opacity: 0;
      transition: opacity .25s ease;
      display: block;
      /* pointer-events: none; */
      a {
	color: #fff;
      }
      h6 {
	margin-top: 0.5rem;
	margin-bottom: 0.25rem;
	color: $color-f13;
      }
    }
    .dropdown:hover > .dropdown-menu {
      /* animation: .1s enable-pointer-events .25s forwards; */
      transition: opacity .25s ease .25s;
      opacity: 1;
    }
  }

  #search-menu {
    .btn-primary {
      color: black;
      background-color: #fff;
      border-color: var(--bs-border-color);
      border-left: none;
    }
  }

  #basket-link {
    background-color: #999;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;

    .fa {
      color: $color-f13;
    }
  }
}

/* menu on top */

body.menu-on-top {

  #header {
    position: fixed;
    width: 100%;
    z-index: 900;
    top: 0;

    #header-brand-bar {
      height: 0;
      overflow: hidden;

      /* .navbar-brand img { */
      /* 	position: fixed; */
      /* 	margin-left: -70px; */
      /* 	height: 70px; */
      /* 	width: 70px; */
      /* } */
    }
  }
}

@include media-breakpoint-up(lg) {
  header {
    .dropdown-menu {
      pointer-events: none;
    }
    .dropdown:hover > .dropdown-menu {
      animation: .1s enable-pointer-events .25s forwards;
    }
  }
}

@include media-breakpoint-down(lg) {
  header {
    #header-menu-bar {
      .dropdown-menu {
	opacity: 1;
      }
    }
    .container-no-gutter {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

@include media-breakpoint-down(xxl) {
  header {
    #header-brand-bar {
      .navbar-brand {
	padding-left: 40px;
	img {
	  position: absolute;
	  height: 45px;
	  width: 45px;
	  margin-left: -50px;
	}
      }
    }
  }
  body.menu-on-top {
    header {
      #search-menu button {
	padding-left: 5px;
	padding-right: 5px;
      }
      #header-menu-bar #menu-navbar {
	margin-left: 45px;
      }
      #header-brand-bar {
	.navbar-brand {
	  img {
	    position: fixed;
	  }
	}
      }
    }
  }
}
