.lightbox.lightbox-modal {
  .btn-close > svg {
    fill: #444;
  }
}

.lightbox {
  .modal-body {
    background-color: $color-bg-body;
  }
  div.ratio {
    background-color: transparent !important;
  }
}

@include media-breakpoint-down(lg) {
  .lightbox.lightbox-modal {
    .modal-body {
      background-color: #fff;
    }
    .modal-content {
      height: calc(100vh - var(--bs-modal-margin) * 2);
      .lightbox-carousel,
      .lightbox-carousel .carousel-inner,
      .lightbox-carousel .carousel-inner .carousel-item {
	height: 100%;
      }
      div.ratio {
	height: 100%;
	width: 100%;
      }
    }
  }
}
