:root {
  --bs-font-sans-serif: "Jost",system-ui,-apple-system,"Segoe UI","Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

.dropdown-menu {
  --bs-dropdown-spacer: 0;
  --bs-dropdown-bg: #444444; /* $color-bg-footer; */
}

.navbar {
  --bs-navbar-brand-color: #000;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.85%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-padding-y: 0;
  --bs-navbar-toggler-padding-x: 7px;
  --bs-border-width: 0;
}

/* customize bootstrap/_forms.scss */
.form-horizontal .control-label {
  padding-top: 5px;
}

.alert .checkbox {
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip {
  /* FIXME: why cant we use the scss var? --bs-tooltip-bg: $color-bg-body; */
  --bs-tooltip-bg: #656565;
  --bs-tooltip-color: var(--bs-white);
}

/* .form-floating > .form-select ~ label.required, */
/* .form-floating > .form-control:not(:placeholder-shown) ~ label.required, */
/* .form-floating > .form-control:focus ~ label.required, */
/* label.required { */
/*   &::after { */
/*     content: "*"; */
/*     color: #ff0000; */
/*     margin-left: 3px; */
/*     text-align: right; */
/*     inset: unset; */
/*   } */
/* } */

.alert {
  .form-check.mb-3 {
    margin-bottom: 0 !important;
  }
}

.accordion {
  .accordion-button {
    cursor: pointer;
  }
}

@include media-breakpoint-down(lg) {
  .d-block-mobile {
    display: block !important;
  }
  .small-mobile {
    font-size: 0.875em;
  }
}

.nav-link-selected {
  color: $text-color;
  background-color: $color-f13;
}

#frontpage-intro {
  .img-cover {
    object-fit: cover;
    width: 100%;
    height: calc(100% - 50px) !important;
  }

  .card-img-overlay {
    padding: 0;

    .card-title {
      background: #fff;
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: var(--bs-card-img-overlay-padding);
      margin-bottom: 0;
    }
  }
}
