#search-menu {
  padding-top: 4px;
}

#live-search-results {
  position: absolute;
  background: #666;
  z-index: 1000;
  padding: 6px 8px 6px 6px;
  border-radius: 0 3px 3px 3px;
  line-height: 1.1em;
  font-size: 90%;
  top: 46px;
  min-width: 250px;
  color: #ddd;
  width: 400px;
  max-width: 100%;

  strong {
    color: #fff;
  }

  a {
    color: #ddd;
  }

  ul {
    padding: 0;
    margin: .2em 0;
    list-style-type: none;
  }

  li {
    padding: .25em;
    margin: 0 0;
  }
  li.category {
    margin: 0.2em 0;
  }
  li:hover,
  li.focus {
    background: #ccc;
    color: #333;
  }
  li:hover a,
  li.focus a {
    color: #333;
    text-decoration: none;
  }
}
