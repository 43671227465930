#elm-audio-player-outer, .media-actions {
  position: relative;

  .cookie-consent-divmask {
    background: #444;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 0px 10px;
    opacity: 0.9;
    z-index: 9;
    h1 {
      margin-top: 10px;
      font-size: 110%;
      color: #fff;
    }
    p {
      font-size: 95%;
      color: #fff;
    }
  }
}

#cookie-consent-popup {
  display: none;

  #cookie-consent-backdrop {
    background: #000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.3;
    z-index: 10000;
  }

  #cookie-consent-inner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10001;

    form {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 900px;
      background: #fff;
      padding: 30px;
      max-height: 80%;
      overflow-y: auto;
      z-index: 10002;

      button {
	margin-bottom: 10px;
      }

      #cookie-consent-details {
	padding-top: 10px;
      }

      #cookie-consent-links {
	padding-top: 10px;
      }

      #cookie-consent-checkboxes {
	margin: 5px 0px 10px 5px;

	label {
	  margin-right: 15px;
	  cursor: pointer;

	  input[type="checkbox"] {
	    display:none;
	  }
	  input[type="checkbox"]:disabled + span {
	    cursor: not-allowed;
	  }
	  input[type="checkbox"] + span::before {
	    width: 15px;
	    height: 15px;
	    border-radius: 2px;
	    border: 2px solid $color-f13;
	    background-color: #fff;
	    display: block;
	    content: "";
	    float: left;
	    margin-right: 5px;
	    margin-top: 3px;
	  }
	  input[type="checkbox"]:checked + span::before {
	    background-color: $color-f13;
	  }
	}
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  #cookie-consent-inner form {
    width: 90%;
  }
}

@include media-breakpoint-down(lg) {
  #cookie-consent-details-div,
  #cookie-consent-buttons {
    text-align: center;
    float: none !important;
  }
  #cookie-consent-checkboxes label {
    width: 42%;
  }
}
