html {
  scroll-behavior: unset !important;
}

body.busy-indicator {
  cursor: wait;
}

img {
  max-width: 100%;
  /* height: auto; */
}

.banner img {
  height: auto;
}

.text-color-f13 {
  color: $color-f13;
}

#content {
  background-color: $color-bg-content;
  /* overflow: clip; */
}

h1 {
  margin-bottom: 1em;
}

.share {
  overflow: hidden;
}

.highlight {
  .extra-line {
    padding-bottom: 3px;
  }
  p {
    line-height: 1.25em;
    font-size: 90%;
  }
}

#intro-box {
  img {
    height: auto;
  }
}
@include media-breakpoint-down(md) {
  #intro-box {
    font-size: 90%;
  }
}

.price {
  font-weight: bold;

  .artikel-label {
    height: 16px;
    padding-top: 0;
  }

  .btn {
    color: #505050;
    padding: 0px 2px;
    font-weight: bold;
    border-width: 0;
  }

  .buy_button {
    display: inline;
    > .btn {
      &:hover {
	background: #36a529; /* FIXME: update to success var */
	color: #ffffff;
      }
    }
  }
}

.article {
  .labels {
    margin-top: -0.4em;
    margin-bottom: 0.4em;
  }

  h3 {
    margin: 0 0 0.4em 0;
    padding-bottom: 0em;
  }

  p {
    margin-top: 0.3em;
    padding-top: 0em;
  }
  .article-link {
    color: #444;
    font-weight: bold;
    line-height: 1.25em;
    &:hover {
      color: $color-f13;
      text-decoration: none;
      img.cover {
	box-shadow: 0px 0px 6px $color-f13;
      }
    }
  }
}

.article-teaser {
  font-size: 80%;
  margin-bottom: 15px;

  .content-area {
    position: relative;
  }
  .price {
    .artikel-label {
      /* xdisplay: block; */
    }
    .fa {
      display: none;
    }
    .btn {
      font-size: 0.7rem;
    }
  }
  .teaser-text {
    width: 60%;
    float:left;
  }
  .article-link {
    font-weight: bold;
    line-height: 1.25em;
    color: #444;
  }
  .artist-title {
    color: #000;
    line-height: 1.25em;
  }
  .extra-line {
    font-size: 0.8em;
    white-space: nowrap;
  }
}

#left-col {
  padding-top: 50px;
  text-align: right;
  a {
    color: #333;
  }
  ul {
    margin: 0px;
    padding: 0px;
    li {
      line-height: 120%;
      margin-bottom: 0.1em;
      list-style-type: none;
    }
    li.parent {
      padding-top: 1em;
      > a {
	font-weight: bold;
	text-transform: uppercase;
	cursor: default;
	&:hover {
	  text-decoration: none;
	}
      }
    }
  }
}

#frontpage-intro {
  .card {
    background: #ccc;
  }

  .card-intro {
    background: $color-bg-content;
    .card-footer {
      background: $color-bg-content;
    }
    h1 {
      padding: 0;
      margin-top: 5px;
    }
    p {
      margin: 0.5em 0 0 0;
      padding: 0;
    }
    a:before {
      content: "» "
    }
  }

  .card-header {
    font-weight: bold;
    background-color: $color-bg-body;
    padding: 5px 0 5px 8px;
    font-size: 100%;
    color: #fff;
    white-space: nowrap;
    text-align: left;
    overflow: hidden;
  }
}

.wrapper_large,
.wrapper_medium,
.wrapper_small {
  margin: 3px 1em 1em 0;
}

.wrapper_medium,
.wrapper_small,
.wrapper_tiny {
  float: left;
}

.wrapper_large {
  width: 100%;
  height: auto;
}

.wrapper_medium {
  width: 100px;
  height: auto;
}

.wrapper_small {
  width: 100%;
  height: auto;
  max-width: 100%;
  margin: 3px 0.5em 0 0;
  img.cover {
    max-width: 100px;
  }
}

.wrapper_tiny {
  width: 50px;
  height: auto;
  max-width: 100%;
  margin: 3px 10px 0 0;
}

.article.small .price-info {
  margin-left: 60px;
  margin-bottom: 1em;
}

img.cover {
  box-shadow: 0 0 6px #555;
  width: 100%;
  height: 100%;
}

.price-icon {
  font-weight: 800;
  color: #fff;
  width: 0.9rem;
  height: 0.9rem;
  display: inline-block;
  text-align: center;
  line-height: 0.9rem;
  border-radius: 1rem;
  font-size: 0.8rem;
}

.icon-margin {
  &:after {
    margin-left: 3px;
  }
}

.cheap-icon {
  &:after {
    @extend .price-icon;
    content: "!";
    background: #2FDB21;
  }
}

.midprice-icon {
  &:after {
    @extend .price-icon;
    content: "M";
    background: #0000FF;
  }
}

/* icon extras */

#social-media-footer {
  --fa-inverse: #444;
  margin: 0;
  padding: 0;

  .fa-stack {
    display: inline-block;
    height: 1.5em;
    line-height: 1.5em;
    width: 2em;
  }

  .fa-stack-2x {
    font-size: 1.5em;
  }

  li {
    display: inline-block;
    list-style-type: none;
  }

  /* .fa-discogs:before { */
  /*   content: " "; */
  /*   background-image: url("../images/discogs.svg"); */
  /*   background-repeat: no-repeat; */
  /*   background-position: 50%; */
  /*   height: 1.5em; */
  /*   width: 1em; */
  /*   display: inline-block; */
  /* } */
}

#social-media {
  margin: 0;
  padding: 0;
  li {
    list-style-type: none;
    padding: 0.2em 0.4em;
    height: 2.5em;
    &:hover {
      background: #ccc;
    }
  }
  a,
  a:hover,
  a:visited {
    color: #000;
    display: block;
    text-decoration: none;
  }
  .fa, .fab {
    color: #aaa;
    font-size: 200%;
    display: inline-block;
    width: 1.25em;
  }
  .fa-facebook {
    color: #3b5998;
  }
  .fa-instagram {
    color: #000;
  }
  .fa-twitter {
    color: #00aced;
  }
}

h2.frontpage {
  font-weight: 500;
  font-size: 1.25rem;
}

.frontpage {
  color: #000;
  display: inline-block;
  background-color: $color-f13;
  padding: 0.5em 1em;
  clip-path: polygon(5% 10%,46% 1%,46% 10%,100% 0,95% 90%,31% 100%,31% 90%,0 100%);
  transition: font-weight .1s ease,
	      color .1s ease,
	      clip-path .2s ease,
	      -webkit-clip-path .2s ease;
}

.bg-gray {
  background: $color-bg-intro;
}

.bg-white {
  background-color: #fff;
}


a {
  &:hover {
    text-decoration: none;
  }
  .frontpage:after {
    content: " »";
  }
  .frontpage:hover {
    font-weight: 800;
    clip-path: polygon(5% 10%,66% 1%,66% 10%,100% 0,95% 90%,51% 100%,51% 90%,0 100%);
  }
}

h2.dateheading {
  @extend .my-3;
  color: #000;
  font-weight: 500;
  font-size: 1.1rem;
  display: inline-block;
  background-color: $color-f13;
  padding: 0.5em 1em;
  clip-path: polygon(5% 10%,46% 1%,46% 10%,100% 0,95% 90%,31% 100%,31% 90%,0 100%);
}

div#coupon-form {
  margin: 0 0 1em;
  padding: 0.3em 0.3em;
  border-radius: 3px;
  xbackground: #009933;
  color: #fff;
  .mb-3 {
    margin-bottom: 0 !important;
  }
}

.orderstatus {
  .col-3 > .inner {
    @extend .d-flex;
    @extend .justify-content-start;
    @extend .align-items-center;
    .num {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.25em;
      font-weight: bold;
      width: 2.5em;
      min-width: 2.5em;
      height: 2.5em;
      background-color: $color-f13;
      color: $text-color;
      border-radius: 2.5em;
      margin-right: 1.5rem !important;
    }
  }
  a {
    color: $text-color;
    transition: font-weight .1s ease;
    &:hover {
      text-decoration: none;
      font-weight: bold;
    }
  }
}

@include media-breakpoint-down(lg) {
  .orderstatus {
    .col-3 > .inner {
      flex-direction: column !important;
      .num {
	margin-bottom: 0.5rem !important;
	margin-right: 0 !important;
      }
    }
  }
}

.milky {
  opacity: 0.4;
}

body.controller-shop.action-page {
  table,
  p {
    max-width: 60em;
  }

  .article-list p {
    max-width: 100%;
  }
}

body.controller-checkout {
  &.action-step2_update,
  &.action-step2 {
    .radio label {
      font-weight: bold;
    }
  }
}

/* for rails-bootstrap-forms */

label.required:after {
  content:" ▪";
  color: #f00;
}

/* fontawesome extras */

a.change-btn:before {
  font-family: "Font Awesome 6 Free";
  content: " ";
  margin-right: 1px;
  margin-left: 4px;
}

/* search */

#search-box {
  .form-check.mb-3 {
    margin-bottom: 0rem !important;
  }
  .card {
    @extend .border-0;
    @extend .small;
    @extend .text-bg-light;
    @extend .mb-3;
    .card-header {
      border-bottom: none;
      background-color: inherit;
    }
    .card-body.card-body-overflow {
      max-height: 12em;
      overflow: auto;
    }
  }
}

/* crew */
body.template-crew {
  .crew-table img {
    max-width: 80% !important;
    padding-bottom: 0.5em;
  }

  .crew-table p {
    max-width: 80% !important;
    @extend .text-center;
    margin-top: 0.5em;
    margin-bottom: 2em;
  }
}

/* youtube */

.media-actions {

  margin-bottom: 10px;

  .media-image {
    box-shadow: 0px 0px 6px #555;
    cursor: pointer;
    display: block;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding-top: 56.25%;
    position: relative;
    > a {
      display: inline-block;
      height: 0px;
      overflow: hidden;
    }
    img.video_img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
    }
    img.youtube_icon {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 25%;
      transform: translate(-50%, -50%);
      pointer-events: none;
    }
  }
  .modal-body {
    background-color: $body-bg;
  }
}

.scrollable-frame {
  max-height: 50vh;
  overflow: auto;
}

body.controller-favorites {
  .favorite-item {
    @extend .border-top;
    @extend .pt-3;

    &:last-child {
      @extend .border-bottom;
    }
  }
}

.article-list {
  .article.highlight {
    @extend .border-top;
    @extend .pt-3;

    &:last-child {
      @extend .border-bottom;
    }
  }
}

#legal-notices {
  @extend .text-muted;
  font-size: 0.8em;
  line-height: 1.25em;
}

@include media-breakpoint-up(xl) {
  .articles-list-xl-up-only-3 {
    .article-teaser:nth-child(4) {
      display: none;
    }
  }
}
@include media-breakpoint-down(xl) {
  .articles-list-xl-down-only-4 {
    .article-teaser:nth-child(5),
    .article-teaser:nth-child(6) {
      display: none;
    }
  }
}
