/* shariff fixes */

#shariff {
  /* display: inline-block; */
  /* width: 160px; */
  /* height: 30px; */
  /* margin-left: 0px; */

  li, li a {
    height: 30px !important;
  }

  .orientation-horizontal li {
    margin: 0 10px 0 0;
    width: 30px;
  }

  .btn-sm {
    padding: 0;
  }

  li .fab,
  li .fas {
    width: 30px;
    display: inline-block;
    font-size: 20px;
    line-height: 30px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .shariff-button a {
    text-align: center;
    border-radius: 20px;
    width: 30px;
  }
}
