/* stripe */

#stripe-card-errors {
  padding: 3px 3px 10px 3px;
  color: $state-danger-text;
}

.StripeElement {
  box-sizing: border-box;

  height: 40px;
  padding: 10px 12px;
  margin: 0;

  border: 1px solid #ddd;
  border-radius: 2px;
  background-color: white;
}

.StripeElement--focus {
  border-color: $input-border-focus;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 204, 0, 0.6);
}

.StripeElement--invalid {
  border-color: $state-danger-text;
}

.StripeElement--complete {
  border-color: $success-border-subtle;
  background-color: $success-bg-subtle !important;
  box-shadow: none;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
