#footer {
  background-color: $color-bg-footer;
  color: $color-fg-footer;
  padding: 2em 1em 1em 1em;

  .contact {
    color: $color-fg-footer;
    font-weight: bold;
  }

  a {
    color: $color-fg-footer;
  }
}
