.elm-audio-player {
  background-color: #fff;
  width: 100%;
  margin-bottom: 15px;

  .title {
    padding: 4px;
    font-weight: bold;
    color: #000;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  * {
    font-size: 0.9rem;
  }

  .playlist-wrapper {
    height: 10em;
    display: block;
    overflow-y: scroll;
  }

  table.playlist {
    width: 100%;

    td {
      padding: 4px 4px;
      vertical-align: top;

      &.playlist-title-artist {
	max-width: 100%;
      }

      &.playlist-duration {
	width: 40px;
	text-align: right;
      }
    }

    tr.playlist-item {
      border-bottom: 1px #cccccc solid;
      color: #555;

      .fa {
	width: 1.5em;
      }

      &.prelistening {
        cursor: pointer;
        color: black;
      }

      &.active {
	background-color: $color-f13;
      }
    }
  }

  .audio-player-container {
    padding: 0;
    background: $color-bg-content;

    audio {
      width: 100%;
      margin: 0;
      display: block;

      &:focus {
	outline: none;
      }
    }
  }
}
