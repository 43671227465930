#newsletter-promo {
  .modal-content {
    border: 3px solid #ffcc00;
    background-color: $color-bg-body;
    box-shadow: 0px 0px 6px #555;

    p {
      color: #fff;
    }
    .btn {
      margin-bottom: 10px;
    }
    .link-close {
      color: #fff;
    }
  }
}
